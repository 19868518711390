import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as LucideIcons from 'lucide-react';

const FirstSlide = () => (
  <div className="flex flex-row w-full h-full">
    <div className="flex flex-col items-start justify-center w-3/5 p-16">
      <h1 className="text-7xl font-bold mb-4 font-styrene text-gray-800">SWARM AI</h1>
      <h2 className="text-3xl font-styrene text-gray-700">The Decentralized Learning Machine</h2>
    </div>
    <div className="flex justify-center items-center w-2/5 bg-white rounded-lg">
      <img src="/swarmai.webp" alt="SWARM AI" className="w-4/5 h-auto" />
    </div>
  </div>
);

const BulletSlide = ({ title, bullets }) => (
  <div className="flex flex-col items-start justify-start w-full h-full p-16">
    <h2 className="text-5xl font-bold mb-6 font-styrene text-gray-800">{title}</h2>
    <div className="w-full h-1 bg-gray-800 mb-10"></div>
    <ul className="list-disc pl-8 space-y-6">
      {bullets.map((bullet, index) => (
        <li key={index} className="text-2xl font-tiempos text-gray-700">{bullet}</li>
      ))}
    </ul>
  </div>
);

const IconSlide = ({ title, points }) => {
  const hasManyPoints = points.length >= 4;
  
  return (
    <div className="flex flex-col items-start justify-start w-full h-full p-16">
      <h2 className="text-4xl font-bold mb-6 font-styrene text-gray-800">{title}</h2>
      <div className="w-full h-1 bg-gray-800 mb-10"></div>
      <ul className={`space-y-${hasManyPoints ? '6' : '8'} w-full`}>
        {points.map((point, index) => {
          const Icon = LucideIcons[point.icon] || LucideIcons.AlertCircle;
          return (
            <li key={index} className="flex items-center space-x-6">
              <Icon size={hasManyPoints ? 30 : 36} className="text-gray-700 flex-shrink-0" />
              <span className={`${hasManyPoints ? 'text-xl' : 'text-2xl'} font-tiempos text-gray-700`}>
                {point.text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const App = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesData, setSlidesData] = useState([]);

  useEffect(() => {
    fetch('/slide_content.json')
      .then(response => response.json())
      .then(data => setSlidesData(data))
      .catch(error => console.error('Error loading slide data:', error));
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev < slidesData.length ? prev + 1 : prev));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const renderSlide = (slideData) => {
    if (slideData.type === 'bullet') {
      return <BulletSlide {...slideData} />;
    } else if (slideData.type === 'icon') {
      return <IconSlide {...slideData} />;
    }
    return null;
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen bg-[#e6e2dd]">
      <div className="relative w-[1024px] h-[576px] bg-[#e6e2dd] rounded-lg shadow-lg overflow-hidden">
        {currentSlide === 0 ? (
          <FirstSlide />
        ) : (
          slidesData[currentSlide - 1] && renderSlide(slidesData[currentSlide - 1])
        )}
        <div className="absolute bottom-4 right-4 flex items-center space-x-4">
          <button
            onClick={prevSlide}
            disabled={currentSlide === 0}
            className="text-gray-400 disabled:opacity-30 hover:text-gray-600 transition-colors"
          >
            <ChevronLeft size={24} />
          </button>
          <span className="text-sm font-styrene text-gray-400">
            {currentSlide + 1} / {slidesData.length + 1}
          </span>
          <button
            onClick={nextSlide}
            disabled={currentSlide === slidesData.length}
            className="text-gray-400 disabled:opacity-30 hover:text-gray-600 transition-colors"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;